import cases from 'assets/images/aboutPage/cases.svg';
import logo from 'assets/images/logo.svg';
import logoBiodiversity from 'assets/images/logoBiodiversity.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

export interface TitleLogoType {
  isAboutPage?: boolean;
}
const TitleLogo = ({ isAboutPage }: TitleLogoType) => {
  const { activeScenarioId } = useWaterScarcity();

  return (
    <>
      <div tw="flex items-center gap-[0.5rem]">
        <img
          css={[tw`sm:w-[2rem] md:hidden`, isAboutPage && tw`hidden`]}
          src={activeScenarioId === 'biodiversity' ? logoBiodiversity : logo}
          alt="Water Crisis Clock Logo"
        />
        <img
          css={[tw`sm:w-[2rem] hidden md:block`, isAboutPage && tw`hidden`]}
          src={logo}
          alt="Water Crisis Clock Logo"
        />
        <div tw="mt-[0.5rem] text-xs sm:m-0">
          {isAboutPage ? (
            <div tw="flex items-center gap-[0.5rem]">
              <h2 css={[tw`text-blue-700 text-sm uppercase`]}>
                {t('title_about_page')}
              </h2>
            </div>
          ) : (
            <>
              <h1
                css={[
                  tw`text-blue-600 uppercase`,
                  activeScenarioId === 'biodiversity' &&
                    tw`text-blue-50 md:text-blue-600`,
                ]}
              >
                {t('title')}
              </h1>
              <span tw="text-blue-400">{t('sub_title')}</span>
            </>
          )}
        </div>
      </div>
      {isAboutPage && (
        <article tw="flex flex-row items-center gap-[2rem 4rem] mt-[3rem] md:flex-col justify-center">
          <img alt="Cases" src={cases} />
          <p tw="md:text-sm text-lg font-medium">
            <span tw="text-blue-700">{t('about_page_5p_1r')}</span>
            {t('about_page_5p_2r')}
          </p>
        </article>
      )}
    </>
  );
};
export default TitleLogo;
