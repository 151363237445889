import bmz from 'assets/images/footer/bmz.svg';
import giz from 'assets/images/footer/giz.svg';
import iiasaBlue from 'assets/images/footer/iiasaBlue.svg';
import React from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterPartners = () => (
  <div tw="p-[2rem 4rem] sm:p-[1rem]">
    <div tw="max-w-[90rem] m-auto">
      <div tw="w-full flex flex-col gap-[0.5rem] text-sm text-[#252525]">
        <h4>Supported by:</h4>
        <div tw="flex sm:flex-col gap-[2rem]">
          <a href="https://www.bmz.de/en">
            <img width={185} height={65} src={bmz} alt="BMZ" />
          </a>
          <a href="https://www.giz.de/en/html/index.html">
            <img width={210} height={55} src={giz} alt="GIZ" />
          </a>
          <a href="https://iiasa.ac.at/">
            <img width={195} height={55} src={iiasaBlue} alt="GIZ" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default FooterPartners;
