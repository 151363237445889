import { theme } from 'twin.macro';
import {
  isWaterAreaSurfaceScenarioActive,
  isWaterScarcityScenarioActive,
} from 'utils/utilities';

export const countryStyle = {
  color: theme`colors.black`,
  dashArray: '0',
  fillColor: theme`colors.gray.200`,
  fillOpacity: 1,
  weight: 0.1,
};

export const handleHoveredLegendCategory = (
  activeScenario: string,
  availability: number
) => {
  if (activeScenario === 'water_area_surface') {
    return availability < -50
      ? 'minimum'
      : availability < -15 && availability >= -50
        ? 'veryLow'
        : availability <= -1 && availability >= -15
          ? 'low'
          : availability <= 0 && availability > -1
            ? 'medium'
            : availability >= 1 && availability < 15
              ? 'high'
              : availability >= 15 && availability < 50
                ? 'veryHigh'
                : availability > 50
                  ? 'maximum'
                  : 'NA';
  } else {
    return availability < 5
      ? 'minimum'
      : availability < 10
        ? 'veryLow'
        : availability < 15
          ? 'low'
          : availability < 20
            ? 'medium'
            : availability < 30
              ? 'high'
              : availability < 50
                ? 'veryHigh'
                : 'maximum';
  }
};

const handleWaterAreaSurfaceColors = (activeScenarioTypeValue: number) =>
  activeScenarioTypeValue < -50
    ? theme`colors.orange.700`
    : activeScenarioTypeValue < -15 && activeScenarioTypeValue >= -50
      ? theme`colors.orange.600`
      : activeScenarioTypeValue <= -1 && activeScenarioTypeValue >= -15
        ? theme`colors.orange.400`
        : activeScenarioTypeValue <= 0 && activeScenarioTypeValue > -1
          ? theme`colors.orange.200`
          : activeScenarioTypeValue >= 1 && activeScenarioTypeValue < 15
            ? theme`colors.blue.100`
            : activeScenarioTypeValue >= 15 && activeScenarioTypeValue < 50
              ? theme`colors.blue.300`
              : activeScenarioTypeValue > 50
                ? theme`colors.blue.500`
                : theme`colors.gray.200`;

const handleWaterScarcityColors = (activeScenarioTypeValue: number) =>
  activeScenarioTypeValue < 5
    ? theme`colors.blue.50`
    : activeScenarioTypeValue < 10
      ? theme`colors.orange.50`
      : activeScenarioTypeValue < 15
        ? theme`colors.orange.100`
        : activeScenarioTypeValue < 20
          ? theme`colors.orange.200`
          : activeScenarioTypeValue < 30
            ? theme`colors.orange.300`
            : activeScenarioTypeValue < 50
              ? theme`colors.orange.500`
              : activeScenarioTypeValue <= 100
                ? theme`colors.orange.600`
                : theme`colors.lighBlue.50`;

const handleBiodiversityColors = (activeScenarioTypeValue: number) =>
  activeScenarioTypeValue < 5
    ? theme`colors.yellow.50`
    : activeScenarioTypeValue < 10
      ? theme`colors.yellow.100`
      : activeScenarioTypeValue < 15
        ? theme`colors.yellow.200`
        : activeScenarioTypeValue < 20
          ? theme`colors.yellow.400`
          : activeScenarioTypeValue < 30
            ? theme`colors.yellow.600`
            : activeScenarioTypeValue < 50
              ? theme`colors.yellow.700`
              : activeScenarioTypeValue <= 100
                ? theme`colors.yellow.800`
                : theme`colors.lighBlue.50`;

export const handleStyle = (
  activeScenarioId: string,
  activeScenarioTypeValue: number
) =>
  isWaterAreaSurfaceScenarioActive(activeScenarioId)
    ? handleWaterAreaSurfaceColors(activeScenarioTypeValue)
    : isWaterScarcityScenarioActive(activeScenarioId)
      ? handleWaterScarcityColors(activeScenarioTypeValue)
      : handleBiodiversityColors(activeScenarioTypeValue);
