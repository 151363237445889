/* eslint-disable max-len */
import close from 'assets/images/blueClose.svg';
import exploreMore from 'assets/images/exploreArrow.svg';
import help from 'assets/images/help.svg';
import nextButton from 'assets/images/nextButton.svg';
import previousButton from 'assets/images/previousButton.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useCasesLegend, useCasesList } from 'utils/useCases';
import { UsecasesLegendType } from '../../interfaces/usecases-legend.interface';

const UseCasesSection = () => {
  const { activeUseCaseIndex, setActiveUseCaseIndex } = useWaterScarcity();

  return (
    <section tw="flex h-[100vh] sm:h-auto">
      <div tw="sm:hidden relative w-[calc(100% - 24rem)] flex">
        <img
          src={useCasesList[activeUseCaseIndex!].map}
          alt="Map"
          tw="object-contain w-full"
        />
        <div tw="absolute lg:hidden bottom-[1rem] left-[1rem] bg-white">
          <div tw="flex flex-col gap-[1rem] border border-blue-400 rounded-[0.5rem] p-[1rem] w-[17.688rem]">
            <h3 tw="text-blue-700 uppercase tracking-[0.2rem] text-sm pt-[0.5rem] pb-[0.5rem]">
              {useCasesLegend[activeUseCaseIndex!].title}
              {useCasesLegend[activeUseCaseIndex!].id === 1 && (
                <span tw="pl-[0.333rem]">
                  <img src={help} alt="Help" tw="max-w-[1rem] inline-flex" />
                </span>
              )}
            </h3>
            {useCasesLegend[activeUseCaseIndex!].legend.map(
              ({ color, value }: UsecasesLegendType) => (
                <div key={value} tw="flex items-center gap-[0 0.4rem] text-sm">
                  <span
                    style={{ backgroundColor: color }}
                    tw="rounded-[50%] w-[1.25rem] h-[1.25rem] block"
                  ></span>
                  <span>{value}</span>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
      <Carousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={100}
        selectedItem={activeUseCaseIndex!}
        onChange={(activeUseCaseIndex) =>
          setActiveUseCaseIndex({
            id: activeUseCaseIndex,
            name: 'activeUseCaseIndex',
          })
        }
        tw="w-full max-w-[32rem] sm:max-w-none sm:overflow-auto relative"
        renderArrowPrev={(clickHandler) => (
          <button
            css={[
              tw`w-[2.35rem] absolute bottom-[0.35rem] left-[1rem] z-[401]`,
              activeUseCaseIndex === 0 && tw`opacity-20`,
            ]}
            disabled={activeUseCaseIndex === 0}
            onClick={clickHandler}
          >
            <img alt="" src={previousButton} />
          </button>
        )}
        renderArrowNext={(clickHandler) => (
          <button
            css={[
              tw`w-[2.35rem] absolute bottom-[0.35rem] z-[401] right-[1rem]`,
              activeUseCaseIndex === useCasesList.length - 1 && tw`opacity-20`,
            ]}
            disabled={activeUseCaseIndex === useCasesList.length - 1}
            onClick={clickHandler}
          >
            <img alt="" src={nextButton} />
          </button>
        )}
      >
        {useCasesList.map((item, index) => {
          if (
            index === activeUseCaseIndex! ||
            index === activeUseCaseIndex! + 1 ||
            index === activeUseCaseIndex! - 1
          ) {
            return (
              <div
                key={item.cover}
                tw="max-w-[32rem] h-full sm:block sm:max-w-none w-full bg-blue-100 flex flex-col right-0"
              >
                <div tw="p-[0.5rem 1rem 0.5rem] sm:p-[1rem] flex flex-col gap-[0.25rem]">
                  <div tw="flex justify-between items-center">
                    <h2 tw="text-blue-750 tracking-[0.2em] font-regular text-sm uppercase">
                      {t('study_cases.study_case_label')}{' '}
                      <span>
                        {item.id + 1}/{useCasesList.length}
                      </span>
                    </h2>
                    <button
                      onClick={() =>
                        setActiveUseCaseIndex({
                          id: null,
                          name: 'activeUseCaseIndex',
                        })
                      }
                    >
                      <img src={close} alt="Close" />
                    </button>
                  </div>
                  <div tw="text-blue-500 font-regular text-sm">
                    <div>
                      {item.types.map((type, id) => (
                        <div
                          tw="flex gap-[0.25rem] mb-[0.5rem] items-center"
                          key={`${type.text}${id}`}
                        >
                          <img
                            tw="!w-[2rem]"
                            src={type.img}
                            alt="usecase type"
                          />
                          <p tw="text-left">{t(`study_cases.${type.text}`)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <h3 tw="text-black text-lg font-medium leading-[1.5rem] text-left">
                    {t(`study_cases.study_case.${item.id}.name`)}
                  </h3>
                </div>
                <img tw="sm:hidden" src={item.cover} alt="Cover" />
                <div tw="flex flex-col gap-[0.25rem] p-[0.5rem 1rem 0.25rem] sm:p-[1rem 1rem 0.25rem] flex-1 overflow-auto">
                  <p
                    tw="overflow-x-auto h-full md:max-h-[18rem] text-left"
                    className="scrollable scrollableUsecases"
                  >
                    {t(`study_cases.study_case.${item.id}.description`)}
                  </p>
                  <div tw="text-blue-700 flex justify-between text-left items-center relative">
                    <div tw="flex flex-col font-medium">
                      <p>{t('study_cases.link_description')}</p>
                      <a
                        href={item.url || 'https://www.giz.de/'}
                        tw="underline"
                      >
                        www.giz.de/
                      </a>
                    </div>
                    <img
                      tw="!w-[1.56rem]"
                      src={exploreMore}
                      alt="Explore more"
                    />
                  </div>
                </div>
                {item.flags.length !== 0 && (
                  <div tw="flex flex-col gap-[0.25rem]">
                    <h4 tw="font-bold text-blue-800 text-sm">
                      {t('study_cases.countries_header')}
                    </h4>
                    <div tw="flex gap-[0.5rem 3.5rem] flex-wrap bg-blue-50 p-[0.5rem 1rem 0] sm:p-[1rem 1rem 0.5rem]">
                      {item.flags.map((flag) => (
                        <div key={flag.id}>
                          <img src={flag.image} alt={`Flag ${flag.image}`} />
                          <p>{flag.country}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return <Fragment key={item.cover}></Fragment>;
          }
        })}
      </Carousel>
    </section>
  );
};

export default UseCasesSection;
