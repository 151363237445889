/* eslint-disable sort-keys */
/* eslint-disable max-len */

import { theme } from 'twin.macro';

export const months: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const scenarios = {
  water_scarcity: {
    description:
      'Percentage of people living in areas with less than 500 cubic metres of available water resources',
    id: 'water_scarcity',
    map_scenario_header: 'of the world population lives in water scarce areas',
    map_type_header: 'Types of view',
    question: 'What is water scarcity?',
    short_description: 'living in water scarce areas',
    title: 'Water scarcity',
    types: [
      {
        description:
          'Total water resources available to a person in cubic meters (excluding desalination plants and imported water in bottles/gallons)',
        description_title: 'Availability',
        id: 'AVAILABILITY',
        title: 'Availability',
      },
      {
        description: 'Number of people per grid cell',
        description_title: 'Population',
        id: 'POPULATION',
        title: 'Population',
      },
    ],
  },
  biodiversity: {
    description:
      'Change in number of share of threatened freshwater-species per basin, weighted by the species range (geographic area where the species lives).',
    id: 'biodiversity',
    map_scenario_header: 'of freshwater species are threatened by extinction',
    map_type_header: 'How biodiversity is affected',
    question: 'What is biodiversity change?',
    short_description:
      'of red lists species have had a significant chance of extinction',
    title: 'Biodiversity',
    types: [
      {
        description: 'Direct + indirect + extraction',
        description_title: 'Overall',
        id: 'OVERALL',
        title: 'Overall',
      },
      {
        description:
          'Wastewater, garbage & solid waste, agricultural and industrial effluents',
        description_title: 'Directly affected by water quality',
        id: 'DIRECT_QUALITY',
        title: 'Direct quality',
      },
      {
        description:
          'Aquacultures, shipping lanes, recreational activities, fishing and harvesting aquatic resources',
        description_title: 'Indirectly affected by water quality',
        id: 'INDIRECT_QUALITY',
        title: 'Indirect quality',
      },
      {
        description: 'Transport of water, utilities, dams and water management',
        description_title: 'Affected by water extraction',
        id: 'EXTRACTION',
        title: 'Extraction',
      },
    ],
  },
  water_area_surface: {
    description:
      'Surface water change is the change in surface water area in hectares per pixel',
    id: 'water_area_surface',
    map_scenario_header: 'of wetlands have changed their size',
    map_type_header: 'Types of surface water',
    question: 'Which unit shows the change in surface water?',
    short_description: 'in global water surface areas',
    title: 'Water surface area change',
    types: [
      {
        description:
          'Permanent + Wetlands Layer + small water surface areas that are too small to be classified',
        description_title: 'Overall',
        id: 'OVERALL',
        title: 'Overall',
      },
      {
        description:
          'Absolute amount of surface water in (larger) lakes and rivers',
        description_title: 'Permanent',
        id: 'PERMANENT',
        title: 'Permanent',
      },
      {
        description:
          'Absolute amount of surface water in regularly flooded natural wetlands, mixed wetlands and water-intensive cultivation (f.ex. Rice paddies)',
        description_title: 'Wetlands',
        id: 'WETLANDS',
        title: 'Wetlands',
      },
    ],
  },
};

export const mapLegend: any = {
  biodiversity: [
    {
      color: theme`colors.lighBlue.50`,
      id: 'NA',
      value: 'NA',
    },
    {
      color: theme`colors.biodiversity.100`,
      id: 'minimum',
      value: '0% - 5%',
    },
    {
      color: theme`colors.biodiversity.200`,
      id: 'veryLow',
      value: '5% - 10%',
    },
    {
      color: theme`colors.biodiversity.300`,
      id: 'low',
      value: '10% - 15%',
    },
    {
      color: theme`colors.biodiversity.400`,
      id: 'medium',
      value: '15% - 20%',
    },
    {
      color: theme`colors.biodiversity.500`,
      id: 'high',
      value: '20% - 30%',
    },
    {
      color: theme`colors.biodiversity.600`,
      id: 'veryHigh',
      value: '30% - 50%',
    },
    {
      color: theme`colors.biodiversity.700`,
      id: 'maximum',
      value: '50% - 100%',
    },
  ],
  population: [
    {
      color: theme`colors.population.100`,
      value: '0',
    },
    {
      color: theme`colors.population.200`,
      value: 'Less than 2.000',
    },
    {
      color: theme`colors.population.300`,
      value: 'Less than 15.500',
    },
    {
      color: theme`colors.population.400`,
      value: 'Less than 95.500',
    },
    {
      color: theme`colors.population.500`,
      value: 'More than or equal to 95.500',
    },
  ],
  water_area_surface: [
    {
      color: theme`colors.water_surface.100`,
      id: 'minimum',
      value: 'More than 50% decrease',
    },
    {
      color: theme`colors.water_surface.200`,
      id: 'veryLow',
      value: '15% - 50% decrease',
    },
    {
      color: theme`colors.water_surface.300`,
      id: 'low',
      value: '1% - 14% decrease',
    },
    {
      color: theme`colors.water_surface.400`,
      id: 'medium',
      value: 'Less than 1% decrease',
    },
    {
      color: theme`colors.water_surface.500`,
      id: 'high',
      value: '1% - 14% increase',
    },
    {
      color: theme`colors.water_surface.600`,
      id: 'veryHigh',
      value: '15% - 50% increase',
    },
    {
      color: theme`colors.water_surface.700`,
      id: 'maximum',
      value: 'More than 50% increase',
    },
    {
      color: theme`colors.gray.200`,
      id: 'NA',
      value: 'NA',
    },
  ],
  water_area_surface_inner: [
    {
      color: theme`colors.orange.700`,
      value: "More than -50' ha",
    },
    {
      color: theme`colors.orange.400`,
      value: '-500ha to -50 ha',
    },
    {
      color: theme`colors.orange.200`,
      value: '-50ha to -1 ha',
    },
    {
      color: theme`colors.blue.100`,
      value: '-1ha to 1 ha',
    },
    {
      color: theme`colors.blue.300`,
      value: '1ha to 50ha',
    },
    {
      color: theme`colors.blue.700`,
      value: '50ha to 500 ha',
    },
    {
      color: theme`colors.blue.800`,
      value: 'More than 500ha',
    },
  ],
  water_scarcity: [
    {
      color: theme`colors.gray.200`,
      id: 'NA',
      value: 'NA',
    },
    {
      color: theme`colors.blue.50`,
      id: 'minimum',
      value: '0% - 5%',
    },
    {
      color: theme`colors.orange.50`,
      id: 'veryLow',
      value: '5% - 10%',
    },
    {
      color: theme`colors.orange.100`,
      id: 'low',
      value: '10% - 15%',
    },
    {
      color: theme`colors.orange.200`,
      id: 'medium',
      value: '15% - 20%',
    },
    {
      color: theme`colors.orange.300`,
      id: 'high',
      value: '20% - 30%',
    },
    {
      color: theme`colors.orange.500`,
      id: 'veryHigh',
      value: '30% - 50%',
    },
    {
      color: theme`colors.orange.600`,
      id: 'maximum',
      value: '50% - 100%',
    },
  ],
  water_scarcity_availability: [
    {
      color: theme`colors.water_scarcity.blue`,
      value: '>1.700 m3',
    },
    {
      color: theme`colors.water_scarcity.cream`,
      value: '1.000 - 1.700 m3',
    },
    {
      color: theme`colors.water_scarcity.light_brown`,
      value: '500 - 1.000 m3',
    },
    {
      color: theme`colors.water_scarcity.brown`,
      value: '<500 m3',
    },
  ],
};

export const caseStudyThemes: any = [
  {
    name: 'all_themes',
    id: 'all',
  },
  {
    name: 'climate_resilience',
    id: 'resilience',
  },
  {
    name: 'ecosystem_protection',
    id: 'protection',
  },
  {
    name: 'ecosystem_restoration',
    id: 'restoration',
  },
  {
    name: 'integrated_management',
    id: 'integrated',
  },
  {
    name: 'transboundary_management',
    id: 'transboundary',
  },
  {
    name: 'water_energy',
    id: 'energy',
  },
  {
    name: 'water_peace',
    id: 'peace',
  },
  {
    name: 'water_quality',
    id: 'quality',
  },
];

export const getActiveScenario: any = (
  activeScenarioId: string,
  mergedScenarios?: any,
) =>
  Object.entries(mergedScenarios || scenarios)
    .filter(([key]) => key === activeScenarioId)
    .map((item) => item[1])[0];

export const getInactiveScenario: any = (
  activeScenarioId: string,
  mergedScenarios?: any,
) =>
  Object.entries(mergedScenarios || scenarios)
    .filter(([key]) => key !== activeScenarioId)
    .map((item) => item[1]);

export const initialYear = 2020;

export const isNotEmptyObject = (object: object) =>
  Object.keys(object).length > 0;

export const isWaterAreaSurfaceScenarioActive = (activeScenario: string) =>
  activeScenario === 'water_area_surface';

export const isWaterScarcityScenarioActive = (activeScenario: string) =>
  activeScenario === 'water_scarcity';

export const findActiveScenarioType: any = (
  activeScenarioTypes: [],
  activeScenarioTypeId: string,
) => activeScenarioTypes?.find(({ id }) => id === activeScenarioTypeId);

export const handleOverflow = (isOverflowHidden: boolean) =>
  (document.body.style.overflow = isOverflowHidden ? 'hidden' : 'auto');

export const useCasesThemes: { id: string; label: string }[] = [
  { id: 'all', label: 'filter_by_theme.all_themes' },
  {
    id: 'transboundary',
    label: 'filter_by_theme.transboundary_management',
  },
  {
    id: 'integrated',
    label: 'filter_by_theme.integrated_management',
  },
  {
    id: 'protection',
    label: 'filter_by_theme.protection',
  },
  { id: 'water', label: 'filter_by_theme.water_peace' },
  { id: 'wefe', label: 'filter_by_theme.wefe' },
  { id: 'climate', label: 'filter_by_theme.climate_resilience' },
];

export const handleMonthIndex = (monthIndex: number, range: string) => {
  switch (range) {
    case 'first':
      return monthIndex >= 1 && monthIndex <= 4;
    case 'middle':
      return monthIndex >= 5 && monthIndex <= 8;
    case 'last':
      return monthIndex >= 9 && monthIndex <= 12;
    default:
      return false;
  }
};
