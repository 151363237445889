/* eslint-disable max-len */
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import cases from 'assets/images/aboutPage/cases.svg';
import arrow from 'assets/images/arrow.svg';
import close from 'assets/images/close.svg';
import help from 'assets/images/help.svg';
import Details from 'components/Country/Details';
import Layer from 'components/Country/Layer';
import Header from 'components/Headers/MapSection/Header';
import Map from 'components/Map/Map';
import ScenariosChange from 'components/MapChangeView/ScenariosChange';
import Legend from 'components/MapLegend/Legend';
import { CustomSelect } from 'components/MapSubHeader/MobileSubHeader';
import Slider from 'components/Slider/Slider';
import { UseCases, UseCasesLabel } from 'components/ToggleSwitch/UseCases';
import { useWaterScarcity } from 'context/waterScarcity';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import React, { useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { isWaterScarcityScenarioActive, useCasesThemes } from 'utils/utilities';

const MapSection = () => {
  const {
    activeScenarioId,
    activeScenarioTypeId,
    areUsecasesActive,
    caseStudyTheme,
    selectedCountry,
    setAreUsecasesActive,
    setActiveUseCaseIndex,
  } = useWaterScarcity();

  const { handleToggleState, toggleState } = useToggle();
  const key = activeScenarioId as string as keyof typeof toggleState;

  const handleChange = (event: SelectChangeEvent) =>
    setActiveUseCaseIndex({
      id: event.target.value,
      name: 'caseStudyTheme',
    });

  const handleOptions = () =>
    useCasesThemes
      .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label))
      .map(({ id, label }) => (
        <MenuItem
          tw="truncate flex items-center text-blue-700 gap-[1rem] font-bold"
          key={id}
          value={id}
        >
          {t(label)}
        </MenuItem>
      ));

  useEffect(
    () => window.scrollTo(window.innerWidth, window.scrollY + 1),
    [selectedCountry],
  );

  return (
    <section id="map_water_surface" tw="py-[1rem] relative mb-[5rem] sm:mb-3">
      <Header />
      <div
        css={[
          tw`flex items-center h-[57%] lg:flex-wrap lg:gap-[2rem]`,
          selectedCountry && tw`h-[25rem] lg:h-auto`,
        ]}
      >
        <article
          css={[
            tw`flex flex-col gap-[0.5rem] max-w-[25rem] lg:max-w-none w-full lg:order-2 lg:p-[1rem 4rem] sm:p-[1rem] pl-[4rem]`,
            selectedCountry && tw`justify-center`,
          ]}
        >
          <div
            css={[
              tw`relative sm:mt-[2rem] gap-[0 0.75rem] rounded-[0.5rem] p-[0.5rem] text-blue-750 text-sm border sm:border-0 border-blue-500 sm:hidden z-[401]`,
              selectedCountry && tw`hidden`,
            ]}
          >
            <div tw="flex flex-col">
              <div tw="flex justify-between">
                <div tw="flex items-center">
                  <UseCases
                    tw="hidden"
                    type="checkbox"
                    checked={areUsecasesActive}
                    id="useCases"
                    onChange={() =>
                      setAreUsecasesActive({
                        id: areUsecasesActive,
                        name: 'areUsecasesActive',
                      })
                    }
                  />
                  <UseCasesLabel htmlFor="useCases" />
                  <label tw="cursor-pointer pl-2" htmlFor="useCases">
                    {t('usecases')}
                  </label>
                </div>
                <button
                  onClick={() => handleToggleState(activeScenarioId)}
                  tw="pl-[0.9rem]"
                >
                  <img src={help} alt="Help" />
                </button>
              </div>
              {areUsecasesActive && (
                <div>
                  <h3 tw="flex justify-between items-center text-sm uppercase tracking-[0.175rem] p-[0.7rem 0 0.5rem]">
                    {t('filter_by_theme.title')}
                  </h3>
                  <CustomSelect
                    value={t(caseStudyTheme)}
                    style={{
                      background: `url(${arrow}) no-repeat 100%`,
                      backgroundPosition: 'right 1rem center',
                    }}
                    tw="border border-blue-500 rounded-[0.5rem] h-[2.75rem] w-full mt-[0.5rem] "
                    onChange={handleChange}
                  >
                    {handleOptions()}
                  </CustomSelect>
                </div>
              )}
            </div>
            <div
              css={[
                tw`sm:hidden absolute translate-x-[calc(-100% - 6rem)] top-0 h-auto bg-blue-700 sm:w-[calc(100% - 1rem)] text-blue-50 rounded-[0.5rem] p-[1rem 0.75rem 1.5rem 1.5rem] flex justify-between flex-col z-[1002]`,
                toggleState[key] && tw`translate-x-[115%]`,
              ]}
            >
              <h3 tw="flex justify-between items-center text-sm uppercase tracking-[0.175rem]">
                {t('usecases')}
                <button onClick={() => handleToggleState(activeScenarioId)}>
                  <img tw="cursor-pointer" src={close} alt="Close" />
                </button>
              </h3>
              <div tw="py-[1rem]">
                <img src={cases} alt="Cases" />
              </div>
              <p tw="text-sm w-[16.125rem]">{t('case_studies_info')}</p>
            </div>
          </div>
          <Legend />
        </article>
        {selectedCountry || activeScenarioTypeId === 'POPULATION' ? (
          <Layer />
        ) : (
          <Map />
        )}
        {isWaterScarcityScenarioActive(activeScenarioId) && selectedCountry && (
          <Details />
        )}
      </div>
      <ScenariosChange />
      <Slider isFromMap={true} />
    </section>
  );
};

export default MapSection;
