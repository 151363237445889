/* eslint-disable max-len */
import water2020 from 'assets/images/heroSection/water2020.svg';
import ExploreMap from 'components/ExploreMap/ExploreMap';
import Header from 'components/Headers/HeroSection/Header';
import ActiveScenarioCard from 'components/HeroSectionScenarioCard/ActiveScenarioCard';
import ScenarioCard from 'components/HeroSectionScenarioCard/ScenarioCard';
import Slider from 'components/Slider/Slider';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { ScenarioProps } from 'interfaces/scenario.interface';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import tw from 'twin.macro';
import {
  area2010Value,
  area2020Value,
  area2030Value,
  backgroundsPath,
  biodiversity2010Value,
  biodiversity2020Value,
  biodiversity2030Value,
  water2010Value,
  water2020Value,
  water2030Value,
  water2040Value,
  water2050Value,
} from 'utils/heroSectionBackgrounds';
import { getInactiveScenario } from 'utils/utilities';
import MobileYearDropdown from '../Slider/MobileYearDropdown';
/** @jsxImportSource @emotion/react */

const HeroSection = () => {
  const { activeScenarioId, activeScenario, overallValues } =
    useWaterScarcity();
  const backgroundRef = useRef<HTMLDivElement>(water2020);

  const handleActiveScenarioBackground = useCallback(() => {
    if (activeScenarioId === 'biodiversity')
      return activeScenario?.overallPercentage > biodiversity2030Value
        ? `${backgroundsPath}bio2030.svg`
        : activeScenario?.overallPercentage > biodiversity2020Value
          ? `${backgroundsPath}bio2020.svg`
          : activeScenario?.overallPercentage > biodiversity2010Value
            ? `${backgroundsPath}bio2010.svg`
            : `${backgroundsPath}bio2000.svg`;
    else if (activeScenarioId === 'water_area_surface') {
      return activeScenario?.overall?.value > area2030Value
        ? `${backgroundsPath}area2030.svg`
        : activeScenario?.overall?.value > area2020Value
          ? `${backgroundsPath}area2020.svg`
          : activeScenario?.overall?.value > area2010Value
            ? `${backgroundsPath}area2010.svg`
            : `${backgroundsPath}area2000.svg`;
    } else {
      return activeScenario?.overall?.value > water2050Value
        ? `${backgroundsPath}water2050.svg`
        : activeScenario?.overall?.value > water2040Value
          ? `${backgroundsPath}water2040.svg`
          : activeScenario?.overall?.value > water2030Value
            ? `${backgroundsPath}water2030.svg`
            : activeScenario?.overall?.value > water2020Value
              ? `${backgroundsPath}water2020.svg`
              : activeScenario?.overall?.value > water2010Value
                ? `${backgroundsPath}water2010.svg`
                : `${backgroundsPath}water2000.svg`;
    }
  }, [activeScenarioId, activeScenario]);

  useLayoutEffect(() => {
    backgroundRef.current.style.backgroundImage = `url(${handleActiveScenarioBackground()})`;
  }, [handleActiveScenarioBackground]);

  return (
    <>
      <Header />
      <ExploreMap />
      <section
        ref={backgroundRef}
        tw="h-[100vh] bg-cover transition-all duration-1000 bg-center md:h-full md:pt-[4rem] md:pb-[2rem]"
        style={{ backgroundImage: `url(${backgroundRef.current})` }}
      >
        <div tw="flex flex-col absolute bottom-[6rem] md:bottom-[1rem] w-full md:p-[1rem 0 1rem 1rem] px-[4rem] gap-[1rem] md:static">
          <div tw="w-full flex flex-col md:flex-row gap-4">
            <div
              tw="relative invisible md:visible md:max-h-[11rem] md:w-px"
              css={[
                tw`bg-blue-700`,
                activeScenarioId === 'biodiversity' && tw`bg-lighBlue-50`,
              ]}
            />
            <p
              css={[
                tw`rounded text-blue-700 font-medium max-w-[29rem] md:max-w-[15rem] xxs:max-w-[10rem] md:text-sm`,
                activeScenarioId === 'biodiversity' && tw`text-lighBlue-50`,
              ]}
            >
              {t('water_crisis_info_text')}
            </p>
            <div
              tw="relative md:hidden w-full h-px"
              css={[
                tw`bg-blue-700`,
                activeScenarioId === 'biodiversity' && tw`bg-lighBlue-50`,
              ]}
            />
          </div>
          <div
            data-testid="scenario-cards"
            tw="md:flex-col flex justify-between gap-[1vw]"
          >
            {
              <ActiveScenarioCard
                activeScenarioId={activeScenarioId}
                id={activeScenario?.id}
                title={t(activeScenario?.title)}
                question={t(activeScenario?.question)}
                description={t(activeScenario?.description)}
                short_description={t(activeScenario?.short_description)}
                overall={activeScenario?.overall}
                overallPercentage={activeScenario?.overallPercentage}
              />
            }
            <div tw="invisible md:visible md:mr-4 md:mb-8">
              <MobileYearDropdown />
            </div>

            <div tw="flex md:overflow-y-auto md:overflow-x-scroll gap-[1rem] md:pr-[1rem]">
              {getInactiveScenario(activeScenarioId, overallValues).map(
                ({
                  id,
                  title,
                  question,
                  short_description,
                  description,
                  overall,
                  overallPercentage,
                }: ScenarioProps) => (
                  <ScenarioCard
                    key={id}
                    id={id}
                    title={t(title)}
                    question={t(question)}
                    description={t(description)}
                    short_description={t(short_description)}
                    overall={overall}
                    overallPercentage={overallPercentage}
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div tw="relative top-[100%]">
          <Slider />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
