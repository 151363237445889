/* eslint-disable max-len */
import FormContainer from 'components/Form/FormContainer';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import React, { useLayoutEffect } from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { handleOverflow } from 'utils/utilities';

export const isNotEmptyObject = (object: object) =>
  Object.keys(object).length > 0;

const RequestData = () => {
  const { handleToggleState, toggleState } = useToggle();

  useLayoutEffect(() => {
    handleOverflow(toggleState.isRequestDataFormVisible);
  }, [toggleState.isRequestDataFormVisible]);

  return (
    <div tw="flex gap-[1rem]">
      <a
        href="/Methodology/methodology.html"
        target="_blank"
        tw="text-center sm:hidden text-blue-800 uppercase text-sm rounded-[0.5rem] bg-[rgba(255, 255, 255, 0.45)] py-[1rem] w-[14.563rem] lg:w-[10rem] lg:text-xs border-0 hover:bg-[white]"
      >
        {t('methodology')}
      </a>
      <button
        onClick={() => handleToggleState('isRequestDataFormVisible')}
        tw="sm:hidden text-blue-800 uppercase text-sm rounded-[0.5rem] bg-[rgba(255, 255, 255, 0.45)] py-[1rem] w-[14.563rem] lg:w-[10rem] lg:text-xs border-0 hover:bg-[white]"
      >
        {t('request_data')}
      </button>
      {toggleState.isRequestDataFormVisible && (
        <div tw="fixed w-full h-full top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0, 0.2)] z-[2]">
          <div
            css={[
              tw`max-w-[41rem] translate-y-[-50%] translate-x-[-50%] w-full sm:rounded-none sm:h-full left-[50%] top-[50%] z-[1] gap-[1rem] text-sm bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]`,
              toggleState.isRequestDataFormVisible &&
                tw`translate-x-[-50%] translate-y-[-50%] z-50 absolute`,
            ]}
          >
            <FormContainer handleToggleState={handleToggleState} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestData;
