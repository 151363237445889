/* eslint-disable max-len */
import diversity from 'assets/images/aboutPage/diversity.png';
import scarcity from 'assets/images/aboutPage/scarcity.png';
import TitleLogo from 'components/Headers/HeroSection/TitleLogo';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const AboutPage = () => (
  <section tw="sm:p-[1rem] px-[4rem] text-gray-800 sm:pb-[1rem] sm:pt-[2rem] py-[5rem] sm:m-0 bg-[#FBFBFB]">
    <div tw="max-w-[90rem] m-auto">
      <TitleLogo isAboutPage={true} />
      <span tw="md:hidden  grid grid-cols-[repeat(3, 1fr)] justify-end mt-[3rem]">
        <span tw="border-t border-t-gray-200"></span>
        <span tw="border-t border-t-gray-200"></span>
        <span></span>
      </span>
      <article tw="grid md:text-sm grid-cols-[repeat(3, 1fr)] justify-center items-start gap-[2rem 4rem] lg:gap-[2rem] mt-[2rem] md:flex flex-col">
        <p tw="md:order-2">
          {t('about_page_1p_1r')}{' '}
          <span tw="text-blue-700">{t('about_page_1p_2r')}</span>{' '}
          {t('about_page_1p_3r')}
          <br />
          <br />
          {t('about_page_1p_4r')}
          <br />
          <br />
          {t('about_page_2p_1r')}
          <span tw="text-blue-700">{t('about_page_2p_2r')}</span>{' '}
          {t('about_page_2p_3r')}
        </p>
        <p tw="md:order-3">
          {t('about_page_2p_4r')}
          <span tw="text-blue-700">{t('about_page_2p_5r')}</span>{' '}
          {t('about_page_2p_16r')}
          <span tw="text-blue-700"> {t('about_page_2p_17r')}</span>{' '}
          {t('about_page_2p_6r')}{' '}
          <span tw="text-blue-700"> {t('about_page_2p_7r')}</span>{' '}
          {t('about_page_2p_8r')}
          <br />
          <br />
          {t('about_page_2p_9r')}{' '}
          <span tw="text-blue-700">{t('about_page_2p_10r')}</span>{' '}
          {t('about_page_2p_11r')}
          <span tw="text-blue-700"> {t('about_page_2p_12r')}</span>{' '}
          {t('about_page_2p_13r')}{' '}
          <span tw="text-blue-700"> {t('about_page_2p_14r')}</span>{' '}
          {t('about_page_2p_15r')}
        </p>
        <img
          tw="object-contain md:max-w-[13rem] lg:self-center md:order-1"
          alt="Scarcity Water"
          src={scarcity}
        />
      </article>
      <span tw="md:hidden grid grid-cols-[repeat(3, 1fr)] justify-end mt-[3rem]">
        <span></span>
        <span tw="border-t border-t-gray-200"></span>
        <span tw="border-t border-t-gray-200"></span>
      </span>
      <article tw="grid md:text-sm grid-cols-[repeat(3, 1fr)] md:flex flex-col justify-center items-start gap-[2rem 4rem] mt-[2rem] lg:gap-[2rem]">
        <img
          tw="object-contain md:max-w-[13rem] lg:self-center"
          alt="Scarcity Water"
          src={diversity}
        />
        <p>
          {t('about_page_3p_1r')}{' '}
          <span tw="text-blue-700">{t('about_page_3p_2r')}</span>{' '}
          {t('about_page_3p_3r')}
          <span tw="text-blue-700">{t('about_page_3p_4r')}</span>
          <br />
          <br />
          {t('about_page_3p_5r')}
          <span tw="text-blue-700">{t('about_page_3p_6r')}</span>
          <br />
          <br />
          {t('about_page_4p_1r')}
        </p>
        <p>
          {'i) '}
          <span tw="text-blue-700">{t('about_page_4p_2r')}</span>
          {t('about_page_4p_3r')}
          <span tw="text-blue-700">{t('about_page_4p_4r')}</span>
          {t('about_page_4p_5r')}
          <br />
          {'ii) '}
          <span tw="text-blue-700">{t('about_page_4p_6r')}</span>
          {t('about_page_4p_7r')}
          <br />
          <br />
          {t('about_page_4p_8r')}
        </p>
      </article>
    </div>
  </section>
);

export default AboutPage;
