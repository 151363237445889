/* eslint-disable max-len */
import close from 'assets/images/close.svg';
import exploreArrowLight from 'assets/images/exploreArrowLight.svg';
import help from 'assets/images/help.svg';
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import MobileYearDropdown from 'components/Slider/MobileYearDropdown';
import { useWaterScarcity } from 'context/waterScarcity';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import { ScenarioProps } from 'interfaces/scenario.interface';
import React, { useEffect } from 'react';
import tw, { theme } from 'twin.macro';
/** @jsxImportSource @emotion/react */

const ActiveScenarioCard = ({
  id,
  title,
  question,
  short_description,
  description,
  overall,
  overallPercentage,
}: ScenarioProps) => {
  const { activeScenarioId, activeScenario } = useWaterScarcity();
  const { handleToggleState, toggleState } = useToggle();
  const key = id as string as keyof typeof toggleState;

  return (
    <article
      css={[
        tw`text-blue-700 relative`,
        activeScenarioId === 'biodiversity' && tw`text-lighBlue-50`,
      ]}
    >
      <div tw="flex items-center uppercase font-medium gap-[0 0.85rem] md:text-regular text-[1.6vw] md:w-[14.5rem] md:pb-[1.438rem]">
        <DynamicSvg
          iconName={id}
          fill={
            activeScenarioId === 'biodiversity'
              ? theme`colors.lighBlue.50`
              : theme`colors.blue.800`
          }
        />
        <h2>{title}</h2>
        <button onClick={() => handleToggleState(id)}>
          <img tw="cursor-pointer" src={help} alt="Help" />
        </button>
      </div>
      <div tw="flex items-center gap-[0.85rem] text-[6vw] font-bold md:text-xxl md:leading-[3.063rem] leading-[8rem]">
        <span>
          {(overallPercentage && `${overallPercentage} %`) ||
            overall?.value.toLocaleString()}
        </span>
      </div>
      <p tw="md:w-[14.5rem] md:py-[0.5rem] md:text-regular text-[1.7vw] pb-[1.563rem]">
        {activeScenarioId === 'water_scarcity' && (
          <span tw="font-bold">people </span>
        )}
        {activeScenarioId === 'water_area_surface' && (
          <span tw="font-bold">hectares increased </span>
        )}
        {activeScenarioId !== 'biodiversity' && (
          <span tw="font-bold">
            (
            {activeScenario?.overallPercentage ||
              activeScenario?.overall?.percentage}
            %){' '}
          </span>
        )}
        {short_description}
      </p>
      <a href="#map_water_surface" tw="underline md:text-sm text-[1.1vw]">
        {t('see_how_within_map')}
      </a>
      <div
        css={[
          tw`translate-y-[-50%] translate-x-[calc(-100% - 6rem)] top-[50%] z-[1] transition-all duration-700 bg-blue-700 absolute w-full md:w-[calc(100% - 1rem)] text-blue-50 rounded-lg p-[1rem 0.75rem 1.5rem 1.5rem] flex justify-between flex-col`,
          toggleState[key] && tw`translate-x-0`,
        ]}
      >
        <div tw="flex justify-between text-sm font-medium uppercase md:text-xs items-start">
          <h3 tw="pb-[2rem] md:pb-0">{title}</h3>
          <button onClick={() => handleToggleState(id)}>
            <img tw="cursor-pointer" src={close} alt="Close" />
          </button>
        </div>
        <div tw="flex flex-col gap-[0.5rem] text-sm">
          <p tw="font-bold">{question}</p>
          <p>{description}</p>
        </div>
        <a
          href="#map_water_surface"
          tw="flex items-center md:text-sm text-regular gap-[0.8rem] font-medium pt-[1rem] md:pt-[0.5rem]"
        >
          {t('explore_more')}
          <img src={exploreArrowLight} alt="Explore more" />
        </a>
      </div>
    </article>
  );
};

export default ActiveScenarioCard;
