import React from 'react';
import MobileFilters from './MobileFilters';
import RequestData from './RequestData';
import TitleLogo from './TitleLogo';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const Header = () => (
  <header tw="flex justify-between items-center absolute w-full p-[1rem 4rem] md:p-[1rem 2rem] sm:p-[1rem] sm:bg-blue-50">
    <TitleLogo />
    <RequestData />
    <MobileFilters />
  </header>
);

export default Header;
