/* eslint-disable max-len */
import { MenuItem, SelectChangeEvent } from '@mui/material';
import cases from 'assets/images/aboutPage/cases.svg';
import arrow from 'assets/images/arrow.svg';
import close from 'assets/images/close.svg';
import exploreArrowLight from 'assets/images/exploreArrowLight.svg';
import help from 'assets/images/help.svg';
import { CustomSelect } from 'components/MapSubHeader/MobileSubHeader';
import SubHeaders from 'components/MapSubHeader/SubHeader';
import InputSearch from 'components/Search/CountryInput';
import { UseCases, UseCasesLabel } from 'components/ToggleSwitch/UseCases';
import { useWaterScarcity } from 'context/waterScarcity';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useCasesThemes } from 'utils/utilities';

const Header = () => {
  const {
    activeScenarioId,
    activeScenario,
    caseStudyTheme,
    areUsecasesActive,
    setAreUsecasesActive,
    selectedCountry,
    setActiveUseCaseIndex,
  } = useWaterScarcity();
  const { handleToggleState, toggleState } = useToggle();

  const key = activeScenarioId as string as keyof typeof toggleState;

  const handleChange = (event: SelectChangeEvent) =>
    setActiveUseCaseIndex({
      id: event.target.value,
      name: 'caseStudyTheme',
    });

  const handleOptions = () =>
    useCasesThemes
      .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label))
      .map(({ id, label }) => (
        <MenuItem
          tw="truncate flex items-center text-blue-700 gap-[1rem] font-bold"
          key={id}
          value={id}
        >
          {t(label)}
        </MenuItem>
      ));

  return (
    <header tw="sm:p-[1rem] px-[4rem] text-blue-700 flex justify-between items-baseline flex-wrap sm:block gap-[2rem]">
      <div tw="flex items-center justify-between relative">
        <div tw="flex flex-col">
          <h3 tw="uppercase tracking-[0.2rem] sm:text-xs text-sm sm:pb-[0.5rem] flex items-center">
            {t(activeScenario?.title)}
          </h3>
          <p tw="sm:w-[11.875rem] w-full max-w-[23rem] sm:text-regular text-xl sm:ml-0">
            <span tw="font-semiBold sm:text-xl text-xxl">
              {activeScenario?.overallPercentage ||
                activeScenario?.overall?.percentage}
              %
            </span>{' '}
            {t(activeScenario?.map_scenario_header)}
          </p>
        </div>
        <div
          css={[
            tw`sm:hidden translate-x-[calc(-100% - 6rem)] top-0 transition-all duration-700 h-auto bg-blue-700 absolute w-full sm:w-[calc(100% - 1rem)] text-blue-50 rounded-lg p-[1rem 0.75rem 1.5rem 1.5rem] flex justify-between flex-col z-[1002]`,
            toggleState[key] && tw`translate-x-0`,
          ]}
        >
          <div tw="flex justify-between text-xl font-medium uppercase">
            <h3>{t(activeScenario?.title)}</h3>
            <button onClick={() => handleToggleState(activeScenarioId)}>
              <img tw="cursor-pointer" src={close} alt="Close" />
            </button>
          </div>
          <div tw="flex flex-col gap-[0.5rem]">
            <p tw="font-bold">{t(activeScenario?.question)}</p>
            <p tw="text-sm">{t(activeScenario?.description)}</p>
          </div>
          <a
            href="/Methodology/methodology.html"
            tw="flex items-center sm:text-sm text-regular gap-[0.8rem] font-medium"
          >
            {t('explore_more')}
            <img src={exploreArrowLight} alt="Explore more" />
          </a>
        </div>
        <button
          onClick={() => handleToggleState(activeScenarioId)}
          tw="sm:hidden pl-[0.9rem]"
        >
          <img src={help} alt="Help" />
        </button>
      </div>
      <div
        css={[
          tw`relative sm:flex mt-[2rem] gap-[0 0.75rem] items-center rounded-[0.5rem] p-[0.5rem] text-blue-750 text-sm border sm:border-0 border-blue-500 max-w-[14.775rem] w-full hidden z-[401]`,
          selectedCountry && tw`sm:hidden`,
        ]}
      >
        <UseCases
          tw="hidden"
          type="checkbox"
          checked={areUsecasesActive}
          id="useCasesMobile"
          onChange={() =>
            setAreUsecasesActive({
              id: areUsecasesActive,
              name: 'areUsecasesActive',
            })
          }
        />
        <UseCasesLabel htmlFor="useCasesMobile" />
        <label tw="cursor-pointer" htmlFor="useCasesMobile">
          {t('usecases')}
        </label>
        <button
          onClick={() => handleToggleState(activeScenarioId)}
          tw="pl-[0.9rem]"
        >
          <img src={help} alt="Help" />
        </button>
        <div
          css={[
            tw`absolute translate-x-[calc(-100% - 6rem)] top-0 h-auto bg-blue-700 text-blue-50 rounded-[0.5rem] p-[1rem 0.75rem 1.5rem 1.5rem] flex justify-between flex-col z-[1002] sm:transition-all sm:duration-700`,
            toggleState[key] && tw`translate-x-[115%] sm:translate-x-[-3%]`,
          ]}
        >
          <h3 tw="flex justify-between items-center text-sm uppercase tracking-[0.175rem]">
            {t('usecases')}
            <button onClick={() => handleToggleState(activeScenarioId)}>
              <img tw="cursor-pointer" src={close} alt="Close" />
            </button>
          </h3>
          <div tw="py-[1rem]">
            <img src={cases} alt="Cases" />
          </div>
          <p tw="text-sm w-[16.125rem]">{t('case_studies_info')}</p>
        </div>
      </div>

      {areUsecasesActive && (
        <div tw="hidden sm:block">
          <h3 tw="flex justify-between items-center text-sm uppercase tracking-[0.175rem] p-[0.7rem 0 0.5rem]">
            {t('filter_by_theme.title')}
          </h3>
          <CustomSelect
            value={t(caseStudyTheme)}
            style={{
              background: `url(${arrow}) no-repeat 100%`,
              backgroundPosition: 'right 1rem center',
            }}
            tw="border border-blue-500 rounded-[0.5rem] h-[2.75rem] w-full mt-[0.5rem] "
            onChange={handleChange}
          >
            {handleOptions()}
          </CustomSelect>
        </div>
      )}

      <div tw="flex justify-between items-center max-w-[25rem] w-full sm:max-w-full sm:mt-[2rem]">
        <InputSearch />
      </div>
      <SubHeaders />
    </header>
  );
};

export default Header;
