/* eslint-disable max-len */
import checked from 'assets/images/checked.svg';
import close from 'assets/images/formClose.svg';
import useForm from 'hooks/useForm';
import { t } from 'i18next';
import { FormTypes } from 'interfaces/form.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { isNotEmptyObject } from 'utils/utilities';

const Form = ({ onValidated, status, handleToggleState }: FormTypes) => {
  const { formData, handleInputChange, handleReset, handleSubmit } = useForm(
    {
      companyEmail: '',
      companyName: '',
      firstName: '',
      joinMailingList: false,
      lastName: '',
      message: '',
    },
    onValidated
  );

  const handleClose = () => {
    handleToggleState('isRequestDataFormVisible');
    handleReset();
  };

  return (
    <>
      <button onClick={handleClose} tw="absolute right-[6%]">
        <img src={close} alt="Close" />
      </button>
      <h2 tw="text-[#3A7CC5] font-medium text-xxl p-[2rem 0 1rem]">
        {t('form_interest')}
      </h2>
      <p tw="max-w-[26.688rem] m-auto text-[#898989] text-regular pb-[1.5rem]">
        {t('form_info')}
      </p>
      <form onSubmit={(e) => handleSubmit(e)} tw="text-sm">
        <div tw="flex  sm:flex-col sm:gap-[1.5rem] items-center justify-between gap-[2rem]">
          <input
            required
            css={[
              tw`border border-[#000000] rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
              formData.firstName !== '' && tw`border border-blue-400`,
            ]}
            type="text"
            placeholder={t('form_first_name') as string}
            name="firstName"
            value={formData.firstName}
            onChange={(e) => handleInputChange(e.target)}
          />
          <input
            required
            css={[
              tw`border border-[#000000] rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
              formData.lastName !== '' && tw`border border-blue-400`,
            ]}
            type="text"
            placeholder={t('form_surname') as string}
            name="lastName"
            value={formData.lastName}
            onChange={(e) => handleInputChange(e.target)}
          />
        </div>
        <input
          required
          css={[
            tw`border border-[#000000] rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none my-[1.5rem]`,
            formData.companyEmail !== '' && tw`border border-blue-400`,
          ]}
          type="email"
          placeholder={t('form_company_email') as string}
          name="companyEmail"
          value={formData.companyEmail}
          onChange={(e) => handleInputChange(e.target)}
        />
        <input
          required
          css={[
            tw`border border-[#000000] rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
            formData.companyName !== '' && tw`border border-blue-400`,
          ]}
          type="text"
          placeholder={t('form_company_name') as string}
          name="companyName"
          value={formData.companyName}
          onChange={(e) => handleInputChange(e.target)}
        />
        <textarea
          required
          tw="border border-[#000000] rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none m-[1.5rem 0 1rem]"
          placeholder={t('form_message') as string}
          name="message"
          value={formData.message}
          onChange={(e) => handleInputChange(e.target)}
        ></textarea>
        <div tw="flex">
          <input
            id="joinList"
            tw="mr-[0.5rem] w-[1.5rem] h-[1.5rem]"
            type="checkbox"
            name="joinMailingList"
            checked={formData.joinMailingList}
            onChange={(e) => handleInputChange(e.target)}
          />
          <label htmlFor="joinList">{t('form_agreements')}</label>
        </div>
        <button
          type="submit"
          disabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.companyEmail ||
            !formData.companyName ||
            !formData.message
          }
          css={[
            tw`bg-[#D7D7D7] border border-[#D7D7D7] p-[0.75rem] text-[#5C5C5C] w-full text-center rounded-[3.813rem] mb-[0.5rem] mt-[1.5rem]`,
            formData.firstName &&
              formData.lastName &&
              formData.companyEmail &&
              formData.companyName &&
              formData.message &&
              tw`bg-blue-400 text-white border border-blue-400 hover:bg-blue-100 hover:text-gray-500`,
          ]}
        >
          {t('request_data')}
        </button>
      </form>
      <p tw="max-w-[28rem] m-auto">
        {t('form_privacy')}{' '}
        <a
          href="https://dashboard.marketpro.io/WDL_Privacy_Policy_FINAL.pdf"
          tw="text-blue-400 underline"
        >
          {t('form_privacy_link')}
        </a>
        .
      </p>
      {status === 'success' && isNotEmptyObject(formData) && (
        <div tw="absolute left-0 top-0 bottom-0 right-0 flex flex-col items-center justify-between bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]">
          <img alt="Checked" src={checked} tw="m-[4rem auto 2rem]" />
          <h2 tw="text-blue-400 font-medium text-xxl mb-[1.5rem]">
            {t('form_received')}
          </h2>
          <p tw="max-w-[33.188rem] w-full text-[#383838] mb-[3rem]">
            {t('form_received_message')}
          </p>
          <button
            onClick={handleClose}
            tw="max-w-[11.625rem] w-full rounded-[3.813rem] mb-[3rem] p-[0.5rem 0.75rem] text-white bg-blue-400 hover:bg-blue-100 hover:text-gray-500"
          >
            {t('form_close')}
          </button>
        </div>
      )}
      {status === 'error' && isNotEmptyObject(formData) && (
        <div tw="absolute left-0 top-0 bottom-0 right-0 flex flex-col items-center justify-between bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]">
          <img alt="Checked" src={checked} tw="m-[4rem auto 2rem]" />
          <h2 tw="text-blue-400 font-medium text-xxl mb-[1.5rem]">
            {t('form_error')}
          </h2>
          <button
            onClick={handleClose}
            tw="max-w-[11.625rem] w-full rounded-[3.813rem] mb-[3rem] p-[0.5rem 0.75rem] text-white bg-blue-400 hover:bg-blue-100 hover:text-gray-500"
          >
            {t('form_close')}
          </button>
        </div>
      )}
    </>
  );
};

export default Form;
