/* eslint-disable max-len */
import close from 'assets/images/close.svg';
import exploreArrow from 'assets/images/exploreArrow.svg';
import exploreArrowLight from 'assets/images/exploreArrowLight.svg';
import help from 'assets/images/help.svg';
import DynamicSvg from 'components/DynamicSVG/DynamicSVG';
import { useWaterScarcity } from 'context/waterScarcity';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import { ScenarioProps } from 'interfaces/scenario.interface';
import React from 'react';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';
import { getActiveScenario, initialYear, scenarios } from 'utils/utilities';

const ScenarioCard = ({
  id,
  title,
  question,
  description,
  overall,
  overallPercentage,
  short_description,
}: ScenarioProps) => {
  const key = id as string as keyof typeof toggleState;
  const { setActiveScenarioIdOrTypeId, selectedYears, setSelectedYears } =
    useWaterScarcity();
  const { handleToggleState, toggleState } = useToggle();

  return (
    <article
      tw="relative text-blue-700 bg-[rgba(255, 255, 255, 0.45)] hover:bg-white hover:cursor-pointer md:w-[13.25rem] w-[21.1vw] rounded-[0.5rem] h-auto md:h-[12.5rem] md:flex-[0 0 75%] transition duration-1000"
      style={{
        transform: `rotateX(0) perspective(1000px) rotateY(${
          toggleState[key] ? '180deg' : '0'
        })`,
        transformStyle: 'preserve-3d',
      }}
    >
      <div
        role="presentation"
        onClick={() => {
          setActiveScenarioIdOrTypeId({
            id,
            name: 'activeScenarioId',
          });
          setActiveScenarioIdOrTypeId({
            id: getActiveScenario(id, scenarios)?.types[0].id,
            name: 'activeScenarioTypeId',
          });
          if (id === 'water_scarcity' && selectedYears.fromYear >= 2040) {
            setSelectedYears({
              id: selectedYears.fromYear,
              name: 'selectedYears',
            });
          } else {
            setSelectedYears({
              id: initialYear,
              name: 'selectedYears',
            });
          }
        }}
        tw="absolute flex flex-col justify-between w-full h-full p-[1vh]"
        style={{
          backfaceVisibility: 'hidden',
          transform: 'rotateX(0)',
          transitionDuration: '600ms',
        }}
      >
        <h3 tw="flex justify-between uppercase items-center md:text-xs text-[0.95vw]">
          {title}
          <button onClick={(e) => handleToggleState(id, e)}>
            <img tw="cursor-pointer" src={help} alt="Help" />
          </button>
        </h3>
        <div tw="flex items-center mt-[1rem] gap-[1vw] md:text-xxl text-[4vw] font-bold">
          <DynamicSvg iconName={id} fill={theme`colors.blue.800`} />
          {selectedYears.fromYear >= 2040 ? (
            'NA'
          ) : overallPercentage || overall?.percentage ? (
            <span>{overallPercentage || overall?.percentage}%</span>
          ) : (
            'NA'
          )}
        </div>
        <p tw="pb-[1rem] text-[1.08vw] leading-[1.5rem] md:text-sm">
          {short_description}
        </p>
        <span tw="flex items-center md:text-sm text-[1.05vw] gap-[0.8rem] font-medium">
          {t('explore_more')}
          <img src={exploreArrow} alt="Explore more" />
        </span>
      </div>
      <div
        tw="absolute flex flex-col justify-between h-full p-[1vh] w-full bg-blue-700 rounded-lg text-blue-50 md:text-xs"
        style={{
          backfaceVisibility: 'hidden',
          transform: 'rotateX(0) rotateY(180deg)',
        }}
      >
        <div>
          <div tw="h-[32px] flex justify-between text-sm font-medium uppercase xl:text-sm md:text-xs items-center">
            <h3 tw="w-[160px]">{title}</h3>
            <button onClick={() => handleToggleState(id)}>
              <img tw="cursor-pointer" src={close} alt="Close" />
            </button>
          </div>
          <div tw="flex flex-col pt-4 gap-4 md:text-xs">
            <p tw="font-bold h-[38px]">{question}</p>
            <p
              tw="text-sm xl:max-h-[7rem]
                    h-full"
            >
              {description}
            </p>
          </div>
        </div>
        <a
          href="#map_water_surface"
          tw="flex items-center md:text-sm text-regular gap-[0.8rem] font-medium xs:text-xs pt-[0.5rem]"
        >
          {t('explore_more')}
          <img src={exploreArrowLight} alt="Explore more" />
        </a>
      </div>
    </article>
  );
};

export default ScenarioCard;
