/* eslint-disable react/jsx-indent */
import Loader from 'components/Loader';
import MapTooltip from 'components/MapTooltip/Tooltip';
import { useWaterScarcity } from 'context/waterScarcity';
import markers from 'data/markers.json';
import useLeaflet from 'hooks/useLeaflet';
import useMarkers from 'hooks/useMarkers';
import { TooltipInfoProps } from 'interfaces/tooltipInfo.interface';
import { LatLngTuple, GeoJSON as LeafletGeoJSON } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer } from 'react-leaflet';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
const centerMap: LatLngTuple = [22, 9.5];

const Map = () => {
  const {
    areUsecasesActive,
    legendHoveredType,
    mapData,
    selectedCountry,
    setActiveCountry,
    setActiveUseCaseIndex,
  } = useWaterScarcity();
  const [mapRef, setMapRef] = useState<any>(null);
  const { handleResize } = useLeaflet(mapRef);
  const { handleMarkers, innerMarkers } = useMarkers();
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfoProps | null>(null);
  const geoJsonLayerRef = useRef<LeafletGeoJSON | null>(null);

  useLayoutEffect(() => {
    if (geoJsonLayerRef.current) {
      geoJsonLayerRef.current.clearLayers().addData(mapData);
    }
  }, [mapData]);

  const highlightFeature = (layer: any) => setTooltipInfo(layer);

  const resetHighlight = () => setTooltipInfo(null);

  const zoomToCountry = ({ target }: any) =>
    target.feature.file_url &&
    setActiveCountry({
      id: target.feature.id,
      name: target.feature.name,
    });

  const mapInteractions = (_: any, layer: any) =>
    layer.on({
      click: zoomToCountry,
      mouseout: resetHighlight,
      mouseover: highlightFeature,
    });

  useEffect(() => {
    if (legendHoveredType) {
      const targetsMap = Object.values(mapRef.target._targets).filter(
        ({ feature }: any) => feature?.hoveredCategory === legendHoveredType,
      );
      geoJsonLayerRef?.current?.setStyle({
        fillOpacity: 0.1,
      });
      targetsMap.forEach((target: any) =>
        target.setStyle({ ...target.feature.style, weight: 0.5 }),
      );
    } else {
      geoJsonLayerRef?.current?.setStyle({
        fillOpacity: 1,
      });
    }
  }, [legendHoveredType, mapRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <article
      data-testid="water-scarcity-map"
      css={[
        tw`w-full relative h-[40rem] 2xl:h-[28rem] sm:h-[20rem] lg:order-1`,
        selectedCountry && tw`hidden`,
      ]}
    >
      {mapData ? (
        <MapContainer
          whenReady={setMapRef as () => void}
          center={centerMap}
          zoom={1.5}
          maxZoom={5}
          minZoom={1}
          doubleClickZoom={false}
          trackResize={false}
          touchZoom={false}
          scrollWheelZoom={false}
        >
          <GeoJSON
            ref={geoJsonLayerRef}
            onEachFeature={mapInteractions}
            data={mapData}
            style={(country: any) => country.style}
          />
          {areUsecasesActive ? handleMarkers() : null}
        </MapContainer>
      ) : (
        <Loader />
      )}
      {innerMarkers.show && (
        <div
          style={{
            left: innerMarkers.position.left,
            top: innerMarkers.position.top,
          }}
          className="fade-in"
          tw="shadow leading-[0.2rem] p-[0.5rem] bg-blue-500 border border-white text-[0.5rem] fixed z-[1040] h-auto flex items-center gap-1 transition-all hover:gap-4 rounded-[4.25rem]"
        >
          {markers[0].data
            .find(({ title }) => title === 'mainJordan')
            ?.inner?.map(({ title, id, icon }) => (
              <button
                className="group"
                key={id}
                onClick={() =>
                  setActiveUseCaseIndex({
                    id,
                    name: 'activeUseCaseIndex',
                  })
                }
              >
                <img width={48} height={52} alt={title} src={icon} />
                <span
                  css={[
                    tw`hidden absolute left-1/2 -translate-x-1/2 -top-12 p-2 group-hover:block text-center bg-blue-100 text-blue-800 rounded text-[0.5rem] tracking-[0.2rem] leading-[0.75rem] w-[16rem]`,
                    id === 7 && tw`-top-[4.5rem]`,
                  ]}
                >
                  {title}
                </span>
              </button>
            ))}
        </div>
      )}
      {tooltipInfo && <MapTooltip tooltipInfo={tooltipInfo as any} />}
    </article>
  );
};

export default Map;
