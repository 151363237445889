/* eslint-disable max-len */
import close from 'assets/images/close.svg';
import help from 'assets/images/help.svg';
import Types from 'components/MapSubHeader/Types';
import TypesDescription from 'components/MapSubHeader/TypesDescription';
import { useWaterScarcity } from 'context/waterScarcity';
import useToggle from 'hooks/useToggle';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const SubHeaders = () => {
  const { activeScenario } = useWaterScarcity();
  const { handleToggleState, toggleState } = useToggle();

  return (
    <div tw="pb-[2rem]">
      <div tw="sm:hidden uppercase tracking-[0.2rem] text-sm flex items-center justify-between gap-[0 1.2rem] relative z-[1001]">
        <h3>{t(activeScenario?.map_type_header)}</h3>
        <button onClick={() => handleToggleState('isScenarioTypesInfoActive')}>
          <img
            src={toggleState.isScenarioTypesInfoActive ? close : help}
            alt={toggleState.isScenarioTypesInfoActive ? 'Close' : 'Help'}
          />
        </button>
        <div
          css={[
            tw`z-[401] hidden normal-case tracking-normal max-w-[25.5rem] top-[10.5rem] gap-[1rem] text-sm bg-blue-700 w-full absolute sm:w-[calc(100% - 1rem)] text-blue-50 rounded-lg p-[1.5rem 1rem] justify-between flex-col right-0`,
            toggleState.isScenarioTypesInfoActive && tw`flex`,
            activeScenario?.types.length <= 2 && tw`w-[125%]`,
          ]}
        >
          <TypesDescription />
        </div>
      </div>
      <div tw="flex justify-between items-center text-sm pt-[1rem] gap-[0.5rem] sm:hidden relative">
        <Types />
      </div>
    </div>
  );
};

export default SubHeaders;
